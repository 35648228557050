/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
@import '~lightgallery/css/lightgallery.css';
@import '~lightgallery/css/lg-thumbnail.css';
@import '~lightgallery/css/lg-zoom.css';
@import '~lightgallery/css/lg-rotate.css';
.info-row {
  display: flex;
}
.info-row .info-row-label {
  width: 7em;
  text-align: right;
}
